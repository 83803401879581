import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "./../../api/client";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

const options = [
  { value: "Processing", label: "Processing" },
  { value: "Accepted", label: "Accepted" },
  { value: "Completed", label: "Completed" },
  { value: "Cancelled", label: "Cancelled" },
];

const UpdateBooking = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  console.log(data);
  const bookingId = data?._id;
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Update the status",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success && selectedOption) {
        const result = await apiClient.post("/booking/update", {
          orderId: bookingId,
          deliveryStatus: selectedOption.value,
        });
        if (result.ok) {
          swal("Updated Booking Status Successfully");
          navigate("/booking/list");
        }
      }
    });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Orders</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/product/list">Bookings</a>
            </li>
            <li className="breadcrumb-item active">Update Booking</li>
          </ol>

          {/* Add prd  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Update Booking Status</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Booking Id*</label>
                          <p>{bookingId}</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Current Status</label>
                          <p>{data.deliveryStatus}</p>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Select Status to Update
                          </label>
                          <Select
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                            name="selectedOption"
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UpdateBooking;
