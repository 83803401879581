import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AuthContext from "./auth/context";
import HeaderComponent from "./HeaderComponent";


const App = () => {
  const [user, setUser] = useState();

  const restoreUser = async () => {
    const user = await sessionStorage.getItem("token");
    if (user) setUser(jwtDecode(user));
  };

  useEffect(() => {
    restoreUser();
  }, []);
  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <Router>
        <HeaderComponent />
        {/* <ComingSoon /> */}
      </Router>
    </AuthContext.Provider>
  );
};

export default App;


